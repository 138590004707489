import axios from 'axios';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {GridSortModel} from "@mui/x-data-grid";
import { useAuth } from '../auth/auth.hooks';
import {EsoInfo} from "./eso.types";

export const useEsoInfo = (
    limit: number,
    page: number,
    search: string[] | undefined,
    instId: string | undefined,
    sort: GridSortModel | undefined,
    columnFilter: object | undefined
): UseQueryResult<EsoInfo[]> => {
    const { accessToken, config } = useAuth();

    let sortField: string | undefined;
    let sortDir: number | undefined;

    if(sort !== undefined && sort.length > 0) {
        sortField = sort[0].field;
        sortDir = sort[0].sort === "asc" ? 1 : -1;
    }

    return useQuery(
        ['eso_m', { 'limit': limit, 'page': page, 'search': search, 'inst_id': instId, 'sort': sort }, columnFilter],
        () =>
            axios
                .get<EsoInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/quantum_info`,
                    { params:{
                            limit,
                            page,
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(instId && {inst_id: instId}),
                            ...(sortField !== undefined ? { sort: sortField } : {}),
                            ...(sortDir !== undefined ? { sort_dir: sortDir } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
};

export const useEsoItem = (id: string): UseQueryResult<EsoInfo[]> => {
    const { accessToken, config } = useAuth();

    return useQuery(
        ['eso_s', { 'id': id }],
        () =>
            axios
                .get<EsoInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/quantum_info`,
                    { params: {
                            object_id: id
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export const useEsoInfoCount = (
    search: string[] | undefined,
    instId: string | undefined,
    columnFilter: object | undefined
): UseQueryResult<number> => {
    const { accessToken, config } = useAuth();

    return useQuery(
        ['eso_rows', { 'search': search, 'inst_id': instId }, columnFilter],
        () =>
            axios
                .get<number>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/quantum_info/rows`,
                    { params:{
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(instId && {inst_id: instId}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}
