import React from "react";
import {useQueryClient, QueryClient} from "@tanstack/react-query";
import {Alert, Box, Container, Divider, Typography} from "@mui/material";
import {useAuth} from "../../auth/auth.hooks";
import {SesInfo} from "../ses.types";
import {CancelButton, InfoFieldPair, UploadStatusMessage} from "../../viewPageCommonElements/viewPage.component";
import {GetString} from "../../../localization";
import {Banner} from "../../banner/banner.component";
import {useSesItem} from "../ses.hooks";
import {useHistory} from "../../history/history.hooks";
import {ParseRevisionData} from "../../history/history.misc";
import {FieldHistorySet} from "../../history/history.types";


export const withHooksHOC = (Component: React.ElementType) => function withHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();
    return <Component accessToken={accessToken} config={config} queryClient={queryClient}/>;
}

interface ShowSesProps {
    objectId: string;
}

interface InfoBoxProps {
    name: string;
    data: SesInfo;
    content: string[];
    historyChanges: FieldHistorySet[];
}

interface ViewSesProps {
    editing: boolean;
    updating: boolean;
    updateStatus: string;
}

interface IHooksHOCProps {
    accessToken: string;
    queryClient: QueryClient;
    config: Record<string, unknown>;
}

const sesInfoContents: string[] = [
    'name',
    'model',
    'type',
    'vendor',
    'instance_id',
    'operating_system',
    'serial_number',
    'patching',
    'networking',
    'firmware',
    'product_version',
]

class ViewSes extends React.Component<any, ViewSesProps> {
    static instId = "";

    static objectId = "";

    static FormatFieldData(data: number | string): string {
        return data as string;
    }

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            editing: false,
            updating: false,
            updateStatus: "none"
        }
    }

    InfoBox = ({name, data, content, historyChanges}: InfoBoxProps): JSX.Element => {
        const {FormatFieldData} = ViewSes;
        const {updating} = this.state;
        const {editing} = this.state;

        const assetName = FormatFieldData(data[content[6]]);

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px"}}>
                <Typography style={{color: "#000000", fontWeight: "bold", fontSize: "larger"}}>
                    {name}
                </Typography>
                <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                <Box style={{display: "flex"}}>
                    <Box style={{float: "left", width: "30%"}}>
                        <InfoFieldPair title={GetString(content[0])}
                                       name={content[0]}
                                       data={FormatFieldData(data[content[0]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[0])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[1])}
                                       name={content[1]}
                                       data={FormatFieldData(data[content[1]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[1])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[2])}
                                       name={content[2]}
                                       data={FormatFieldData(data[content[2]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[2])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[3])}
                                       name={content[3]}
                                       data={FormatFieldData(data[content[3]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[3])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                    </Box>
                    <Box style={{float: "left", width: "30%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[4])}
                                       name={content[4]}
                                       data={FormatFieldData(data[content[4]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[4])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[5])}
                                       name={content[5]}
                                       data={FormatFieldData(data[content[5]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[5])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[6])}
                                       name={content[6]}
                                       data={FormatFieldData(data[content[6]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[6])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[7])}
                                       name={content[7]}
                                       data={FormatFieldData(data[content[7]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[7])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                    </Box>
                    <Box style={{float: "right", width: "30%", flexGrow: 2}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[8])}
                                       name={content[8]}
                                       data={FormatFieldData(data[content[8]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[8])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[9])}
                                       name={content[9]}
                                       data={FormatFieldData(data[content[9]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[9])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[10])}
                                       name={content[10]}
                                       data={FormatFieldData(data[content[10]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[10])}
                                       objectId={ViewSes.objectId}
                                       source="ses_info"
                                       assetName={assetName}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }

    ShowSes = ({objectId}: ShowSesProps): JSX.Element => {
        if(objectId === "") {
            return <Alert severity="error" sx={{ mb: 2 }}>ID was not provided</Alert>
        }

        const {InfoBox} = this;

        const assetData = useSesItem(objectId);
        const revisionData = useHistory("ses_info", objectId)

        const revisionChanges = ParseRevisionData(revisionData.data);

        return (
            <Box>
                {assetData.isLoading && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}
                {assetData.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Asset data could not be loaded</Alert>
                )}
                {assetData.isSuccess && (
                    <Box>
                        <Box className="viewAssetContainer">
                            <InfoBox
                                name="Asset Information"
                                data={assetData.data[0]} content={sesInfoContents}
                                historyChanges={revisionChanges}
                            />
                            {/* <AssetInfoBox
                            name="Installation Details"
                            data={assetData.data[0]} content={assetInfoContents}
                        /> */}
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }

    render(): JSX.Element {
        const {ShowSes} = this;
        const {editing} = this.state;
        const {updating} = this.state;
        const {updateStatus} = this.state;

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const params = parts[2].split("+")

        // eslint-disable-next-line prefer-destructuring
        ViewSes.instId = decodeURIComponent(params[1]);
        // eslint-disable-next-line prefer-destructuring
        ViewSes.objectId = params[0];

        return (
            <Box>
                <Banner firstLine="SES Asset Data" secondLine={`View Asset ID ${ViewSes.instId}`}/>
                <Container maxWidth="xl">
                    <form id="editForm">
                        <Box style={{ float: "right" }}>
                            <CancelButton
                                editing={editing}
                                updating={updating}
                                onClick={() => {
                                    this.setState({
                                        editing: false
                                    })
                                }}
                            />
                        </Box>
                        <Box className="clearBoth">
                            <UploadStatusMessage status={updateStatus}/>
                        </Box>
                        <Box>
                            <ShowSes objectId={ViewSes.objectId}/>
                        </Box>
                    </form>
                </Container>
            </Box>
        );
    }
}

export default withHooksHOC(ViewSes);
