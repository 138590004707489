import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";
import { Banner } from "../banner/banner.component";


export function Home(): JSX.Element {
    return (
        <Box>
            <Banner firstLine="Hello" secondLine="Welcome to the OTAM platform" />
            <Container maxWidth="xl">
                <Box className="homePageFlexContainer">
                    <Box className="homePageFlexItem" style={{ minWidth: 615 }} sx={{ pt: 2 }}>
                        <Typography variant="h1" sx={{ mb: 3 }}>
                            What is OTAM?
                        </Typography>
                        <Typography className="homePageDescription" sx={{ pr: 4 }}>
                            <p>
                                OTAM stands for Operational Technology Asset Management. But what is &quot;OT&quot; and why is it important?
                            </p>

                            <p>
                                Operational Technology (OT) is the hardware and software utilized to regulate and control physical devices and processes within an organization containing Industrial Control Systems (ICS). Traditionally, operational technology is set up in an “air gap” environment, meaning it lives within an isolated network with no interaction with the outside network. The only way to move data in and out of an air-gapped device is through physical means like a thumb drive. However, many organizations that utilize Industrial Control Systems (ICS) no longer use a truly air-gapped network, which expands the attack surface adversaries can use to exploit operational technology within the grid.
                            </p>

                            <p>
                                Asset management has been an integral component of IT security for years and is used to proactively reduce the risk of cyber attacks. As the gap between IT and OT closes, it becomes equally important to manage the OT asset space.
                            </p>
                        </Typography>
                        <br/>
                        <br/>
                        <Typography>
                            Find out more through the following links:
                            <ul className="homePageList" style={{ lineHeight: "170%" }}>
                                <li><Link className="genericLink" target="_blank" href="https://confluence.devops.wartsila.com/display/OC/OTAM+General+Overview">OTAM General Overview</Link></li>
                                <li><Link className="genericLink" target="_blank" href="https://confluence.devops.wartsila.com/display/OC/Asset+Data+Guidelines">Asset Data Guidelines</Link></li>
                                <li><Link className="genericLink" target="_blank" href="https://confluence.devops.wartsila.com/display/OC/Asset+Inventory+Scanning+Tool">Asset Inventory Scanning Tool</Link></li>
                                <li><Link className="genericLink" target="_blank" href="https://confluence.devops.wartsila.com/display/OC/Accessing+the+Asset+Data">Accessing the Asset Data</Link></li>
                            </ul>
                        </Typography>
                    </Box>
                    <Box className="homePageFlexItem" style={{ minWidth: 615 }} sx={{ pt: 2 }}>
                        <Typography variant="h1" sx={{ mb: 3 }}>
                            Have you registered your product&apos;s digital inventory?
                        </Typography>
                        <Typography className="homePageDescription" sx={{ pr: 4 }}>
                            <p>
                                Digital inventory is the digital world equivalent to bill of materials for physical equipment. Understanding both the software and hardware components included in digital products serves as the foundation for identifying vulnerabilities, weaknesses and other issues that get found over time. Without knowing what exactly goes into our products, majority of discovered vulnerabilities become impossible to react to.
                            </p>
                            <p>
                                Following are just some of the examples of benefits to having a comprehensive, up-to-date list of assets available:
                            </p>
                            <ul className="homePageList">
                                <li>React to newly discovered vulnerabilities in hardware and software assets</li>
                                <li>Produce and deploy patches to equipment on the field to meet customer requirements and contract terms</li>
                                <li>Patch and secure service offerings provided and operated by Wärtsilä</li>
                                <li>Meet compliance requirements towards customers</li>
                                <li>Evaluate cyber security quality of products acquired from vendors</li>
                            </ul>
                        </Typography>
                        <br/>
                        <Typography style={{ lineHeight: "170%" }}>
                            <ul>
                                <li className="listIconTouch"><Link className="genericLink" target="_blank" href="https://my.cyber.wartsila.com/products">Register your product and digital hardware inventory today through MyCyber</Link></li>
                                <li className="listIconLight"><Link className="genericLink" target="_break" href="mailto:psirt@wartsila.com">For more information, contact psirt@wartsila.com</Link></li>
                            </ul>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
