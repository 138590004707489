import * as React from 'react';
import {useEffect} from "react";

import {useMsal} from "@azure/msal-react";
import {InteractionRequiredAuthError} from "@azure/msal-browser";
import { AuthContext, IAuthContext } from '../../index';

export const useAuth = (): IAuthContext => {
    const {instance} = useMsal();
    const [account] = instance.getAllAccounts();
    const context = React.useContext(AuthContext);

    const request = {
        account,
        scopes: [`${context.config.REACT_APP_API_CLIENT_ID}/.default`],
    }

    useEffect(() => {
        instance.acquireTokenSilent(request)
            .then((response) => {
                if(context.setToken !== undefined) {
                    context.setToken(response.accessToken);
                }
                else {
                    console.error("'context.setToken' was undefined. Acquired access token cannot be updated.");
                }
            })
            .catch((error) => {
                if(error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(request);
                }
            })
    })

    return context;
};
