import {useQuery, UseQueryResult} from "@tanstack/react-query";
import axios from "axios";
import {useAuth} from "../auth/auth.hooks";
import {HistoryInfo} from "./history.types";


export const useHistory = (source: string, id: string): UseQueryResult<HistoryInfo> => {
    const { accessToken, config } = useAuth();

    return useQuery(
        ['history', {'source': source, 'id': id}],
        () =>
            axios
                .get<HistoryInfo>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/${source}/history/${id}?remap=true`,
                    { headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}
