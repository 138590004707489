import {Alert, Box, Container} from "@mui/material";
import React from "react";
import {Banner} from "../banner/banner.component";
import {CustomDataGrid} from "../dataGrid/dataGrid.component";
import {useHistory} from "../history/history.hooks";
import {ParseRevisionData} from "../history/history.misc";

export function FieldRevisions(): JSX.Element {
    const {pathname} = window.location;
    const parts = pathname.split("/");
    const params = parts[3].split("+");

    const source = parts[2];
    const objectId = params[0];
    const assetName = decodeURIComponent(params[1]);
    const header = decodeURIComponent(params[2]);
    const field = params[3];

    const columnConfig = [
        {field: "value", headerName: "Value", minWidth: 80, flex: 2, editable: false, sortable: false},
        {field: "date", headerName: "Date", minWidth: 80, flex: 2, editable: false, sortable: false},
        {field: "version", headerName: "Version", minWidth: 80, flex: 1.5, editable: false, sortable: false},
        {field: "edited_by", headerName: "Edited By", minWidth: 80, flex: 3, editable: false, sortable: false},
    ]

    const revisionData = useHistory(source, objectId)
    const revisionChanges = ParseRevisionData(revisionData.data);
    const revisions = revisionChanges.find(x => x.name === field);

    return(
        <Box>
            <Banner firstLine="Revision History" secondLine={`${assetName}, ${header}`} gridBanner/>
            <Container maxWidth="xl">
                <Box>
                    {revisionData.isError && (
                        <Alert severity="error" sx={{ mb: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>Something went wrong while loading backend status</Alert>
                    )}
                    {!revisionData.isError && (
                        <Box>
                            <Box style={{height: 670, width: "100%"}}>
                                <CustomDataGrid
                                    allowSearch={false}
                                    columnConfig={columnConfig}
                                    itemInfo={revisions !== undefined ? revisions.changes : undefined}
                                    rowCount={revisions !== undefined ? revisions.changes.length : 0}
                                    rowIds={(row: any) => row.version}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    );
}
