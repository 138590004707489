export const searchCache: {[key: string]: string[] | undefined;} = {};
export const columnSearchCache: {[key: string]: {[k: string]: any} | undefined;} = {};

export const tabCache: {[key: string]: number | undefined} = {};

export function searchCacheGet(key: string): string[] | undefined {
    // Make sure key exists
    if(key in searchCache) {
        return searchCache[key];
    }

    return undefined;
}

export function searchCacheSet(key: string, value: string[] | undefined): void {
    searchCache[key] = value;
}

export function columnSearchCacheGet(key: string): {[k: string]: any} {
    // Make sure key exists
    if(key in columnSearchCache) {
        const temp = columnSearchCache[key];
        return temp === undefined ? {} : temp;
    }

    return {};
}

export function columnSearchCacheSet(key: string, value: {[k: string]: any} | undefined): void {
    columnSearchCache[key] = value;
}

export function tabCacheGet(key: string): number | undefined {
    // Make sure key exists
    if(key in tabCache) {
        return tabCache[key];
    }

    return undefined;
}

export function tabCacheSet(key: string, value: number | undefined): void {
    tabCache[key] = value;
}
