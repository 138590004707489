import {Box, Container, Typography} from "@mui/material";
import React from "react";

interface BannerProps {
    firstLine: string;
    secondLine: string;
    gridBanner?: boolean;
}

export function Banner({firstLine, secondLine, gridBanner}: BannerProps): JSX.Element {
    const bannerType: string = gridBanner ? "gridBanner" : "banner"

    return(
        <Container maxWidth="xl">
            <Box className={bannerType} maxWidth="xl" style={{minWidth: "864px"}}>
                <Typography
                    className="bannerText"
                    variant="h1"
                    style={{lineHeight: 1.4}}
                    sx={{paddingLeft: 4}}>
                    {firstLine}
                    <br/>
                    {secondLine}
                </Typography>
            </Box>
        </Container>
    );
}
