export type AssetScanInfo = {
    object_id: number;
    name: string;
    type: string;
    ip_addresses: string[];
    mac_addresses: string[];
    version: string;
    firmware_version: string;
    patching_level: string[];
    vendor: string;
    model: string;
    operating_system: string;
    serial_number: string;
    product_version: string;
    installation_id: string;
    installed_software: string[];
    hardware_order_number: string;
    memory_card_serial: string;
    source: string;
    [key: string]: (string | string[] | number);
};

export function IsFieldArray(key: string): boolean {
    switch (key) {
        case "ip_addresses":
        case "mac_addresses":
        case "patching_level":
        case "installed_software":
            return true;
        default:
            return false;
    }
}
