import {NavLink} from "react-router-dom";
import {Box} from "@mui/material";
import {AuthenticateRolesLocally, RoleAuthResult} from "../auth/rolesAuth";

interface ButtonBaseProps {
    text: string;
    path: string;
    requiredRoles: string[];
}

export function ButtonBase({text, path, requiredRoles}: ButtonBaseProps): JSX.Element | null {
    if(requiredRoles.length !== 0 && AuthenticateRolesLocally(requiredRoles) !== RoleAuthResult.RoleFound) return null;

    return <Box className="navigationButton" style={{
        paddingTop: 15,
        paddingBottom: 13,
    }}>
        {/* This is not a MUI component, so it can't be styled with the global theme in index.tsx */}
        <NavLink to={path}
                 style={(navData) => ({
                     color: "#ff7321",
                     marginTop: 0,
                     marginLeft: 0,
                     marginRight: 0,
                     marginBottom: 0,
                     paddingTop: 17,
                     paddingLeft: 10,
                     paddingRight: 10,
                     paddingBottom: 14,
                     fontFamily: "Noto Sans",
                     fontSize: 16,
                     fontWeight: navData.isActive ? "normal" : "lighter",
                     textTransform: "none",
                     textDecoration: navData.isActive ? "underline" : "none",
                     textUnderlineOffset: 14,
                     textDecorationThickness: navData.isActive ? 5 : 0,
                 })}>
            <span style={{color: "#ffffff"}}>
                {text}
            </span>
        </NavLink>
    </Box>
}
