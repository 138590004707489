import React, {useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
// import CloseIcon from '@mui/icons-material/Close';
// import CheckIcon from '@mui/icons-material/Check';
import {Box, Container, debounce, Divider, FormControlLabel, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import {Banner} from "../banner/banner.component";
import {useEquipmentInfo, useInstallationInfo} from "./installation.hooks";
import {EquipmentInfo, InstallationListInfo} from "./installation.types";

const debounceMs = 500;

interface customDropDownListPropsInstallation {
    data: InstallationListInfo[] | undefined;
    success: boolean;
    loading: boolean;
    error: boolean;
    search: string;
    navigate: NavigateFunction;
}

interface customDropDownListPropsEquipment {
    data: EquipmentInfo[] | undefined;
    success: boolean;
    loading: boolean;
    error: boolean;
    search: string;
    navigate: NavigateFunction;
}

interface customDropdownItemInstallationProps {
    name: string;
    id: number;
    navigate: NavigateFunction;
    // assetData?: boolean;
}

interface customDropdownItemEquipmentProps {
    name: string;
    id: string;
    objId: string;
    navigate: NavigateFunction;
    type: string;
}

function CustomDropdownItemInstallation({name, id, navigate /* , assetData */ }: customDropdownItemInstallationProps): JSX.Element {
    return (
        <Box
            className="dropDownItem"
            style={{ padding: "15px" }}
            onClick={() => {
                navigate({pathname: `/installations/${id}`});
            }}
        >
            <Box style={{ display: "flex" }}>
                <Typography
                    fontWeight="normal"
                    style={{ float: "left", width: "50%" }}
                >
                    {name}
                </Typography>
{/*                {assetData ? <CheckIcon/> : <CloseIcon/>}
                <Typography
                    className={assetData ? "assetsTextTrue" : "assetsTextFalse"}
                    style={{ float: "right", width: "50%", flexGrow: 1 }}
                >
                    Asset Data
                </Typography> */}
            </Box>
            <Box style={{ clear: "both" }}>
                <Typography>
                    {id}
                </Typography>
            </Box>
        </Box>
    );
}

function CustomDropdownItemEquipment({name, id, objId, navigate, type}: customDropdownItemEquipmentProps): JSX.Element {
    return (
        <Box
            className="dropDownItem"
            style={{ padding: "15px" }}
            onClick={() => {
                switch (type) {
                    case "Module":
                        navigate({pathname: `/engine_data/${objId}+${id}`})
                        break;

                    case "Asset Scan":
                        navigate({pathname: `/asset_scan_data/${objId}+${id}`})
                        break;

                    case "ES&O":
                        navigate({pathname: `/eso_data/${objId}+${id}`})
                        break;

                    case "Plantnet":
                        navigate({pathname: `/plantnet/${objId}+${id}`})
                        break;

                    case "SES":
                        navigate({pathname: `/ses_data/${objId}+${id}`})
                        break;

                    case "SCR":
                        navigate({pathname: `/scr_data/${objId}+${id}`})
                        break;

                    case "sWOIS":
                        navigate({pathname: `/swois_data/${objId}+${id}`})
                        break;

                    case "WDCU":
                        navigate({pathname: `/wdcu_data/${objId}+${id}`})
                        break;

                    case "PCS":
                        navigate({pathname: `/pcs_data/${objId}+${id}`})
                        break;

                    default:
                        console.warn("View page could not be loaded");
                        break;
                }
            }}
        >
            <Typography>
                {name} ({id})
            </Typography>
        </Box>
    );
}

function CustomDropDownListInstallation({data, success, loading, error, navigate, search}: customDropDownListPropsInstallation): JSX.Element | null {
    if(loading) {
        return(
            <Typography style={{ paddingTop: "7px", paddingBottom: "7px", paddingLeft: "15px" }}>Loading...</Typography>
        );
    }

    if(success && data !== undefined && data.length === 0 && search.length >= 3) {
        return(
            <Typography style={{ paddingTop: "7px", paddingBottom: "7px", paddingLeft: "15px" }}>No results found...</Typography>
        );
    }

    if(error) return null;

    const items = [];

    if(data !== undefined && data !== null && data.length > 0) {
        for(let i = 0; i < data.length; i += 1) {
            if(i !== 0) {
                items.push(<Divider key={`div-${i}`}/>);
            }

            items.push(<CustomDropdownItemInstallation
                key={String(i)}
                name={data[i].name}
                id={data[i].installation_id}
                // assetData={data[i].asset_data}
                navigate={navigate}/>);
        }
    }

    if(items.length > 0) {
        return(
            <Box>
                {items}
            </Box>
        );
    }

    return null;
}

function CustomDropDownListEquipment({data, success, loading, error, navigate, search}: customDropDownListPropsEquipment): JSX.Element | null {
    if(loading) {
        return(
            <Typography style={{ paddingTop: "7px", paddingBottom: "7px", paddingLeft: "15px" }}>Loading...</Typography>
        );
    }

    if(success && data !== undefined && data.length === 0 && search.length >= 3) {
        return(
            <Typography style={{ paddingTop: "7px", paddingBottom: "7px", paddingLeft: "15px" }}>No results found...</Typography>
        );
    }

    if(error) return null;

    const items = [];
    const maxItems = Math.min(data === undefined ? 0 : data.length, 10);

    if(data !== undefined && data !== null && data.length > 0) {
        for(let i = 0; i < maxItems; i += 1) {
            if(i !== 0) {
                items.push(<Divider key={`div-${i}`}/>);
            }

            items.push(<CustomDropdownItemEquipment
                name={data[i].name}
                id={data[i].id}
                objId={data[i].object_id}
                navigate={navigate}
                type={data[i].type}/>);
        }
    }

    if(data !== undefined && data.length > 10) {
        items.push(<Divider key="div-sm"/>);
        items.push(
            <Typography
                className="dropDownItem"
                style={{ padding: "15px" }}
                onClick={() => {
                    navigate({pathname: `/equipment/${search}`});
                }}
            >
                See more items...
            </Typography>
        )
    }

    if(items.length > 0) {
        return(
            <Box>
                {items}
            </Box>
        );
    }

    return null;
}

export function Installation(): JSX.Element {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>("");
    const [radioValue, setRadioValue] = useState<string>("installation");
    const installations = useInstallationInfo(search, 10, radioValue === "installation");
    const equipment = useEquipmentInfo(search, 0, 11, radioValue === "equipment");
    const navigate = useNavigate();

    let searchItemsInstallation;
    let searchItemsEquipment;
    let isSuccess;
    let isLoading;
    let isError;

    if(radioValue === "installation") {
        isSuccess = installations?.isSuccess;
        isLoading = installations?.isLoading;
        isError = installations?.isError;
        searchItemsInstallation = (installations !== null && isSuccess) ? installations.data : [];
    }
    else {
        isSuccess = equipment?.isSuccess;
        isLoading = equipment?.isLoading;
        isError = equipment?.isError;
        searchItemsEquipment = (equipment !== null && isSuccess) ? equipment.data : [];
    }

    const debounceSearch = debounce((newValue: string) => setSearch(newValue), debounceMs);

    React.useEffect(() => {
        if(!open) {
            searchItemsInstallation = [];
            searchItemsEquipment = [];
        }
    }, [open]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    return (
        <Box>
            <Banner firstLine="Installations" secondLine="Search"/>
            <Container maxWidth="xl">
                <Typography style={{ marginBottom: "12px" }}>
                    To search for an installation, please type the name, functional location ID or the project number.
                </Typography>
                <Box>
                    <Typography
                        style={{ display: "inline", verticalAlign: "middle", marginLeft: "3px", marginRight: "25px" }}
                    >
                        Search in
                    </Typography>
                    <RadioGroup
                        value={radioValue}
                        onChange={handleChange}
                        row
                        style={{display: "inline"}}
                    >
                        <FormControlLabel control={<Radio/>} label="Installation" value="installation"/>
                        <FormControlLabel control={<Radio/>} label="Equipment" value="equipment"/>
                    </RadioGroup>
                </Box>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: 'left', marginTop: "3px" }}>
                    <TextField
                        className="instSearchBox"
                        id="instSearchField"
                        label="Search"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const newInputValue = event.target.value;
                            if(newInputValue === undefined || newInputValue === null || newInputValue === "") {
                                setOpen(false);
                                debounceSearch("");
                            }
                            else {
                                setOpen(true);
                                debounceSearch(newInputValue);
                            }
                        }}
                    />
                </Box>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: 'left' }}>
                    <Box className="dropDownList">
                        {radioValue === "installation" &&
                            <CustomDropDownListInstallation data={searchItemsInstallation} search={search} success={isSuccess} loading={isLoading} error={isError} navigate={navigate}/>}
                        {radioValue === "equipment" &&
                            <CustomDropDownListEquipment data={searchItemsEquipment} search={search} success={isSuccess} loading={isLoading} error={isError} navigate={navigate}/>}
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
