import * as React from "react";
import { Outlet } from 'react-router-dom'
import {Alert, Box} from "@mui/material";
import {AuthenticateRolesLocally, RoleAuthResult} from "../auth/rolesAuth";

interface PrivateRouteProps {
    requiredRoles: string[];
}

interface PrivateRouteErrorMessageProps {
    message: string;
}

export function PrivateRouteErrorMessage({message}: PrivateRouteErrorMessageProps): JSX.Element {
    return(
        <Box style={{marginTop: "10px", marginLeft: "10%", marginRight: "10%"}}>
            <Alert severity="info">{message}</Alert>
        </Box>
    );
}

export function PrivateRoutes({requiredRoles}: PrivateRouteProps): JSX.Element {
    switch (AuthenticateRolesLocally(requiredRoles)) {
        case RoleAuthResult.NoRoles:
            return <PrivateRouteErrorMessage message="No roles found"/>

        case RoleAuthResult.NoMatchingRole:
            return <PrivateRouteErrorMessage message="Access denied. Please request the appropriate role from the OTAM team."/>

        case RoleAuthResult.RoleFound:
            return <Outlet/>

        case RoleAuthResult.NoAuthFound:
        default:
            return <PrivateRouteErrorMessage message="No authorization"/>
    }
}
