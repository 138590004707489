export function validateMacAddress(mac: string): boolean {
    // If string is empty, don't validate it
    if(!mac) return true;

    /* MAC addresses can either be separated into 6 sets of 2 hexadecimal digits separated by
     * colon (:) or dash (-), or 3 sets of 4 hexadecimal digits separated by a dot (.)
     */

    let regex = /^[0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}[:-][0-9a-f]{2}$/i

    let result = regex.test(mac)

    if (!result) {
        regex = /^[0-9a-f]{4}[.][0-9a-f]{4}[.][0-9a-f]{4}$/i

        result = regex.test(mac)
    }

    return result
}
