import React, {FormEvent, useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import axios from "axios";
import {useQueryClient, QueryClient} from "@tanstack/react-query";
import {Alert, Box, Collapse, Container, Divider, List, ListItem, Typography} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {useAuth} from "../../auth/auth.hooks";
import {GetString} from "../../../localization";
import {InstalledSoftware, NetworkSwitchInfo, SiteAssetInfo} from "../sites.types";
import {Banner} from "../../banner/banner.component";
import {useSiteAsset} from "../sites.hooks";
import {
    CancelButton,
    EditButton, InfoDropdownPair,
    InfoFieldPair,
    UploadStatusMessage
} from "../../viewPageCommonElements/viewPage.component";
import {validateMacAddress} from "../../misc";
import {useHistory} from "../../history/history.hooks";
import {ParseRevisionData} from "../../history/history.misc";
import {FieldHistorySet} from "../../history/history.types";

type UrlParams = {
    object_id: string;
    hostname: string;
    site: string;
}

export const withHooksHOC = (Component: React.ElementType) => function withHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();

    const {pathname} = window.location;
    const parts = pathname.split("/");
    const urlParams = parts[4].split("+");

    const params: UrlParams = {
        object_id: urlParams[0],
        hostname: decodeURIComponent(urlParams[1]),
        site: parts[2]
    }
    return <Component accessToken={accessToken} config={config} queryClient={queryClient} params={params}/>;
}

interface ShowSiteProps {
    objectId: string;
}

interface InfoBoxProps {
    name: string;
    data: SiteAssetInfo;
    content: string[];
    historyChanges: FieldHistorySet[];
}

interface InstInfoBoxProps {
    name: string;
    data: NetworkSwitchInfo;
    content: string[];
    historyChanges: FieldHistorySet[];
}

interface ViewSiteProps {
    editing: boolean;
    updating: boolean;
    updateStatus: string;
    dataLoaded: boolean;
    currentType: string;
}

interface InstallationListBoxProps {
    name: string;
    data: InstalledSoftware[];
    content: string[];
}

interface IHooksHOCProps {
    accessToken: string;
    queryClient: QueryClient;
    config: Record<string, unknown>;
    params: UrlParams
}

const siteInfoContents: string[] = [
    'hostname',
    'mac_address',
    'vendor',
    'model',
    'type',
    'created_at',
    'last_seen',
    'firmware_version',
    'serial_number',
    "vlan",
]

const siteInfoContentsAsset: string[] = [
    'hostname',
    'mac_address',
    'vendor',
    'model',
    'type',
    'created_at',
    'last_seen',
    'firmware_version',
    'serial_number',
    'ip_address',
    "vlan",
]

const siteInfoContentsPc: string[] = [
    'hostname',
    'mac_address',
    'vendor',
    'model',
    'type',
    'created_at',
    'last_seen',
    'os',
    'os_version',
    'serial_number',
    'ip_address',
    'vlan',
]

const siteInfoContentsAdd: string[] = [
    'hostname',
    'mac_address',
    'vendor',
    'model',
    'type',
    'os',
    'os_version',
    'firmware_version',
    'serial_number',
    "vlan",
]

const installedSoftwareContents: string[] = [
    'name',
    'version',
    'install_date',
]

const networkSwitchContents: string[] = [
    "name",
    "sw_ip",
    "interface",
    "description"
]

const assetTypes = [
    "",
    "Firewall",
    "Switch",
    "computer",
    "PLC",
    "gateway",
    "OT_device",
    "camera",
    "IOT_device",
    "router",
    "mobile_device",
    "media_converter",
    "controller",
    "cctv_camera"
]

// NOTE: This array should have the same number of items as the above
// and be in the same order
const assetTypeNames = [
    "",
    "Firewall",
    "Switch",
    "Computer",
    "PLC",
    "Gateway",
    "OT Device",
    "Camera",
    "IOT Device",
    "Router",
    "Mobile Device",
    "Media Converter",
    "Controller",
    "CCTV Camera"
]

const netSwPrefix = "network_switch_info/";

class ViewSiteAsset extends React.Component<IHooksHOCProps, ViewSiteProps> {
    static addReturn = {
        "object_id": "",
        "hostname": "",
    };

    static InstallationListBox = ({name, data, content}: InstallationListBoxProps): JSX.Element => {
        const [collapse, setCollapse] = useState(true);

        const installations = Object(data);

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px", cursor: "pointer"}}>
                <Box sx={{display: "flex"}} onClick={() => {
                    setCollapse(!collapse);
                }}>
                    <Typography style={{color: collapse ? "#ff7321" : "#000000", fontWeight: "bold", fontSize: "larger"}}>
                        {name}
                    </Typography>
                    <Box sx={{ml: "10px"}}>
                        {collapse ? <KeyboardArrowDownIcon sx={{pt: "3px"}}/> : <KeyboardArrowUpIcon sx={{pt: "3px"}}/>}
                    </Box>
                </Box>
                <Collapse in={!collapse} sx={{cursor: "auto"}}>
                    <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                    <Box>
                        <List>
                            {installations === null || Object.keys(installations).length === 0 ?
                                <br/> : installations.map((item: { [x: string]: string }) => (
                                    <ListItem key={ item[content[0]] + item[content[1]] + item[content[2]]}>
                                        <Typography>
                                            <strong>{item[content[0]]}</strong>
                                            <br/>
                                            {item[content[1]]}
                                            <br/>
                                            {item[content[2]] !== undefined && item[content[2]].split("T")[0].replace(/-/g, "/")}
                                            <br/>
                                        </Typography>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Box>
                </Collapse>
            </Box>
        );
    }

    static FormatFieldData(data: number | string | string[] | object): string {
        if(Array.isArray(data)) {
            const list = data.toString();
            return list.split(",").join("\n");
        }

        if(typeof data === "object" && data !== null) {
            return ""
        }

        return data as string;
    }

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            editing: false,
            updating: false,
            updateStatus: "none",
            dataLoaded: false,
            currentType: "",
        }

        ViewSiteAsset.InstallationListBox = ViewSiteAsset.InstallationListBox.bind(this);
    }

    componentDidMount(): void {
        const {params} = this.props;

        if (params.object_id === "add") {
            this.setState({
                editing: true
            })
        }
    }

    InfoBox = ({name, data, content, historyChanges}: InfoBoxProps): JSX.Element => {
        const [collapse, setCollapse] = useState(false);

        const {FormatFieldData} = ViewSiteAsset;
        const {updating} = this.state;
        const {editing} = this.state;
        const {currentType} = this.state;
        const {params} = this.props;

        const assetName = FormatFieldData(data[content[0]]);
        const createdAt = new Date(FormatFieldData(data[content[5]]));
        const lastSeen = new Date(FormatFieldData(data[content[6]]));
        const lastValidDate = new Date();
        lastValidDate.setMonth(lastValidDate.getMonth() - 3);
        const tooOld = lastSeen < lastValidDate;

        useEffect(() => {
            if(editing) {
                setCollapse(false);
            }
        },[editing])

        const onChange = (newValue: string): void => {
            if(newValue !== currentType) {
                this.setState({
                    currentType: newValue,
                })
            }
        }

        const collapseArrowStyle = {pt: "3px", color: editing ? "#c0c0c0" : "#000000"}

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px", cursor: editing ? "default" : "pointer"}}>
                <Box sx={{display: "flex"}} onClick={() => {
                    if(!editing) {
                        setCollapse(!collapse);
                    }
                }}>
                    <Typography style={{color: collapse ? "#ff7321" : "#000000", fontWeight: "bold", fontSize: "larger"}}>
                        {name}
                    </Typography>
                    <Box sx={{ml: "10px"}}>
                        {collapse ? <KeyboardArrowDownIcon sx={collapseArrowStyle}/> : <KeyboardArrowUpIcon sx={collapseArrowStyle}/>}
                    </Box>
                </Box>
                <Collapse in={!collapse} sx={{cursor: "auto"}}>
                    <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                    <Box style={{display: "flex"}}>
                        <Box style={{float: "left", width: "30%"}}>
                            <InfoFieldPair title={GetString(content[0])}
                                           name={content[0]}
                                           data={FormatFieldData(data[content[0]])}
                                           updating={updating}
                                           editing={editing}
                                           mandatory={currentType === "Firewall"}
                                           historyData={historyChanges.find(x => x.name === content[0])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[1])}
                                           name={content[1]}
                                           data={FormatFieldData(data[content[1]])}
                                           updating={updating}
                                           editing={editing}
                                           mandatory={currentType !== "Firewall"}
                                           historyData={historyChanges.find(x => x.name === content[1])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[2])}
                                           name={content[2]}
                                           data={FormatFieldData(data[content[2]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[2])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[3])}
                                           name={content[3]}
                                           data={FormatFieldData(data[content[3]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[3])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                        </Box>
                        <Box style={{float: "left", width: "30%"}}>
                            <InfoDropdownPair title={GetString(content[4])}
                                              name={content[4]}
                                              data={FormatFieldData(data[content[4]])}
                                              dropdownOptions={assetTypes}
                                              onChangeCallback={onChange}
                                              updating={updating}
                                              editing={editing}
                                              historyData={historyChanges.find(x => x.name === content[4])}
                                              objectId={params.object_id}
                                              source="internal"
                                              assetName={assetName}
                                              dropdownDisplayNames={assetTypeNames}
                            />
                            <InfoFieldPair title={GetString(content[5])}
                                           name={content[5]}
                                           data={createdAt.toDateString()}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[5])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                                           editable={false}
                            />
                            <InfoFieldPair title={GetString(content[6])}
                                           name={content[6]}
                                           data={lastSeen.toDateString()}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[6])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                                           showDateWarning={tooOld}
                                           editable={false}
                            />
                            <InfoFieldPair title={GetString(content[7])}
                                           name={content[7]}
                                           data={FormatFieldData(data[content[7]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[7])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                        </Box>
                        <Box style={{float: "left", width: "30%"}}>
                            <InfoFieldPair title={GetString(content[8])}
                                           name={content[8]}
                                           data={FormatFieldData(data[content[8]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[8])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[9])}
                                           name={content[9]}
                                           data={FormatFieldData(data[content[9]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[9])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[10])}
                                           name={content[10]}
                                           data={FormatFieldData(data[content[10]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[10])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[11])}
                                           name={content[11]}
                                           data={FormatFieldData(data[content[11]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[11])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[12])}
                                           name={content[12]}
                                           data={FormatFieldData(data[content[12]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[12])}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        );
    }

    InfoBoxInst = ({name, data, content, historyChanges}: InstInfoBoxProps): JSX.Element => {
        const [collapse, setCollapse] = useState(true);

        const {FormatFieldData} = ViewSiteAsset;
        const {updating} = this.state;
        const {editing} = this.state;
        const {params} = this.props;

        const assetName = FormatFieldData(data[content[0]]);

        useEffect(() => {
            if(editing) {
                setCollapse(false);
            }
        },[editing])

        const collapseArrowStyle = {pt: "3px", color: editing ? "#c0c0c0" : "#000000"}

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px", cursor: editing ? "default" : "pointer"}}>
                <Box sx={{display: "flex"}} onClick={() => {
                    if(!editing) {
                        setCollapse(!collapse);
                    }
                }}>
                    <Typography style={{color: collapse ? "#ff7321" : "#000000", fontWeight: "bold", fontSize: "larger"}}>
                        {name}
                    </Typography>
                    <Box sx={{ml: "10px"}}>
                        {collapse ? <KeyboardArrowDownIcon sx={collapseArrowStyle}/> : <KeyboardArrowUpIcon sx={collapseArrowStyle}/>}
                    </Box>
                </Box>
                <Collapse in={!collapse} sx={{cursor: "auto"}}>
                    <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                    <Box style={{display: "flex"}}>
                        <Box style={{float: "left", width: "50%"}}>
                            <InfoFieldPair title={GetString(content[0])}
                                           name={content[0]}
                                           idPrefix={netSwPrefix}
                                           data={FormatFieldData(data[content[0]])}
                                           multiline={Array.isArray(data[content[0]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === `${netSwPrefix}${content[0]}`)}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[1])}
                                           name={content[1]}
                                           idPrefix={netSwPrefix}
                                           data={FormatFieldData(data[content[1]])}
                                           multiline={Array.isArray(data[content[1]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === `${netSwPrefix}${content[1]}`)}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[2])}
                                           name={content[2]}
                                           idPrefix={netSwPrefix}
                                           data={FormatFieldData(data[content[2]])}
                                           multiline={Array.isArray(data[content[2]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === `${netSwPrefix}${content[2]}`)}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[3])}
                                           name={content[3]}
                                           idPrefix={netSwPrefix}
                                           data={FormatFieldData(data[content[3]])}
                                           multiline={Array.isArray(data[content[3]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === `${netSwPrefix}${content[3]}`)}
                                           objectId={params.object_id}
                                           source="internal"
                                           assetName={assetName}
                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        );
    }

    PostData = (data: string, postType: string, type: string): void => {
        const {queryClient} = this.props;
        const {config} = this.props;
        const {accessToken} = this.props;
        const {params} = this.props;

        const conf = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        }

        axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/upload/internal/${postType}`, data, conf)
            .then((response) => {
                if(response.status === 200) {
                    // Backend data has been updated, so local cache is no longer valid
                    queryClient.invalidateQueries(['site_asset', { 'id': params.object_id }], { exact: true })
                    queryClient.invalidateQueries(['history', { 'source': "internal", 'id': params.object_id}])
                }

                ViewSiteAsset.addReturn.object_id = response.data.object_id;
                ViewSiteAsset.addReturn.hostname = response.data.hostname;

                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: params.object_id === "add" ? "added" : "success"
                })
            }).catch(error => {
            if(!error.response) {
                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "failed"
                })
            }
            else if(error.response.status === 409) {
                if(type === "Firewall") {
                    this.setState({
                        editing: true,
                        updating: false,
                        updateStatus: "host-already-exists"
                    })
                }
                else {
                    this.setState({
                        editing: true,
                        updating: false,
                        updateStatus: "mac-already-exists"
                    })
                }
            }
            else {
                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "failed"
                })
            }
        })
    }

    EditFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        const {editing} = this.state;
        const {params} = this.props;

        e.preventDefault();

        if(!editing) {
            this.setState({
                editing: true
            });
        }

        if(editing) {
            this.setState({
                updating: true,
                updateStatus: "updating"
            })

            const form = document.getElementById("editForm") as HTMLFormElement;
            const data = new FormData(form);

            data.set("object_id", params.object_id);
            data.append("facility", params.site);

            const object = {
                network_switch_info: {
                    name: "",
                    sw_ip: "",
                    interface: "",
                    description: ""
                }
            } as SiteAssetInfo;

            data.forEach((value, key)=> {
                if(key.startsWith(netSwPrefix)) {
                    object.network_switch_info[key.replace(netSwPrefix, '')] = value as string;
                }
                else {
                    object[key] = value as string;
                }

            });

            if(object.type === "Firewall") {
                if(object.hostname === "") {
                    this.setState({
                        updateStatus: "mandatory",
                        updating: false
                    })
                    return;
                }
            }
            else {
                // eslint-disable-next-line no-lonely-if
                if(object.mac_address !== "") {
                    if(!validateMacAddress(object.mac_address)) {
                        this.setState({
                            updateStatus: "macError",
                            updating: false
                        })
                        return;
                    }
                }
                else {
                    this.setState({
                        updateStatus: "mandatory",
                        updating: false
                    })
                    return;
                }
            }

            const json = JSON.stringify(object);

            if(params.object_id === "add") {
                this.PostData(json, "add", object.type)
            }
            else {
                this.PostData(json, "update", object.type)
            }
        }
    }

    ShowSite = ({objectId}: ShowSiteProps): JSX.Element => {
        if(objectId === "") {
            return <Alert severity="error" sx={{ mb: 2 }}>ID was not provided</Alert>
        }

        const {InfoBox} = this;
        const {InfoBoxInst} = this;
        const {dataLoaded} = this.state;
        const {InstallationListBox} = ViewSiteAsset;

        if(objectId === "add") {
            if(!dataLoaded) {
                this.setState({
                    dataLoaded: true
                });
            }

            const newAsset: SiteAssetInfo = {
                object_id: -1,
                hostname: "",
                mac_address: "",
                mac_vendor: "",
                vendor: "",
                model: "",
                type: "",
                ip_address: "",
                ip_cidr: "",
                os: "",
                os_version: "",
                firmware_version: "",
                serial_number: "",
                vlan: "",
                created_at: "",
                created_by: "",
                last_seen: "",
                user: "",
                last_updated_by: "",
                protocols: [],
                installed_software: [],
                firewall_info: {
                    fw_address: "",
                    int_description: "",
                    interface: "",
                },
                network_switch_info: {
                    name: "",
                    sw_ip: "",
                    description: "",
                    interface: "",
                },
                facility: -1,
            };

            return (
                <Box>
                    <Box className="viewAssetContainer">
                        <InfoBox
                            name="Asset Information"
                            data={newAsset} content={siteInfoContentsAdd}
                            historyChanges={[]}
                        />
                    </Box>
                </Box>
            );
        }

        const revisionData = useHistory("internal", objectId)
        const revisionChanges = ParseRevisionData(revisionData.data);

        const assetData = useSiteAsset(objectId);

        if(assetData.isSuccess && !dataLoaded) {
            this.setState({
                dataLoaded: true
            });
        }

        let contents: string[] = [];
        if(assetData.data !== undefined) {
            switch(assetData.data[0].type) {
                case "":
                    contents = siteInfoContentsAsset;
                    break;
                case "computer":
                    contents = siteInfoContentsPc;
                    break;
                case "Firewall":
                case "Switch":
                default:
                    contents = siteInfoContents;
                    break;
            }
        }

        return (
            <Box>
                {assetData.isLoading && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}
                {assetData.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Asset data could not be loaded</Alert>
                )}
                {assetData.isSuccess && (
                    <Box>
                        <Box className="viewAssetContainer">
                            <InfoBox
                                name="Asset Information"
                                data={assetData.data[0]} content={contents}
                                historyChanges={revisionChanges}
                            />
                        </Box>
                        <Box className="viewAssetContainer">
                            { "network_switch_info" in assetData.data[0] &&
                                <InfoBoxInst
                                    name="Switch Port Details"
                                    data={assetData.data[0].network_switch_info} content={networkSwitchContents}
                                    historyChanges={revisionChanges}
                                /> }
                        </Box>
                        <Box className="viewAssetContainer">
                            { assetData.data[0].type === "computer" && assetData.data[0].installed_software.length > 0 &&
                            <InstallationListBox
                                name={GetString("installed_software")}
                                data={assetData.data[0].installed_software}
                                content={installedSoftwareContents}
                            />}
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }

    render(): JSX.Element {
        const {ShowSite} = this;
        const {editing} = this.state;
        const {updating} = this.state;
        const {updateStatus} = this.state;
        const {params} = this.props;
        const {dataLoaded} = this.state;

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const urlParams = parts[4].split("+");

        // eslint-disable-next-line prefer-destructuring
        params.object_id = urlParams[0];
        params.hostname = decodeURIComponent(urlParams[1]);
        // eslint-disable-next-line prefer-destructuring
        params.site = parts[2];

        return (
            <Box>
                {params.object_id === "add" && updateStatus === "added" &&
                    // Navigate to the regular view asset page if adding new asset was successful.
                    // "replace" overwrites the add page with the view page we are navigating to, instead
                    // of adding the view page to the history.
                    <Navigate
                        to={`/internal_sites/${params.site}/view/${ViewSiteAsset.addReturn.object_id}+${ViewSiteAsset.addReturn.hostname}`}
                        replace
                    />
                }
                <Banner
                    firstLine="Internal Site Asset"
                    secondLine={
                        params.object_id === "add" && updateStatus !== "added" ? "Add New Asset" : `View Asset ${params.hostname ? params.hostname : params.object_id}`
                    }
                />
                <Container maxWidth="xl">
                    <form id="editForm" onSubmit={this.EditFormSubmit}>
                        <Box style={{ float: "right" }}>
                            {params.object_id !== "add" && (
                                <CancelButton
                                    editing={editing}
                                    updating={updating}
                                    onClick={() => {
                                        this.setState({
                                            editing: false
                                        })
                                    }}
                                />
                            )}

                            <EditButton requiredRoles={["otam_admin", "otam_internal"]} editing={editing} updating={updating} enabled={dataLoaded}/>
                        </Box>
                        <Box className="clearBoth">
                            <UploadStatusMessage status={updateStatus}/>
                        </Box>
                        <Box>
                            <ShowSite objectId={params.object_id}/>
                        </Box>
                    </form>
                </Container>
            </Box>
        );
    }
}

export default withHooksHOC(ViewSiteAsset);
