import React, {useEffect, useRef, useState} from "react";
import {Alert, Box, Button, Container, SelectChangeEvent} from "@mui/material";

import {useNavigate} from "react-router-dom";
import {GRID_CHECKBOX_SELECTION_COL_DEF, GridColumnVisibilityModel, GridFilterModel, GridRenderCellParams} from "@mui/x-data-grid";
import {CustomDataGrid} from "../dataGrid/dataGrid.component";
import {Banner} from "../banner/banner.component";
import {useSiteInfo} from "./sites.hooks";
import {searchCacheGet, searchCacheSet} from "../dataGrid/searchCache";


interface gridProps {
    searchCacheKey: string;
    useColumnFilters?: boolean;
}

const initialVisibilityModel = {
    object_id: false,
    facility: false,
};

export function SitesGrid({searchCacheKey, useColumnFilters}: gridProps): JSX.Element {
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
    const [search, setSearch] = useState<any[] | undefined>(searchCacheGet(searchCacheKey));
    const [visibilityModel, setVisibilityModel] = useState<any>(initialVisibilityModel);

    const filter = useRef<any[] | undefined>(undefined);
    const siteInfo = useSiteInfo(paginationModel.pageSize, paginationModel.page, search);
    const navigate = useNavigate();

    const columnConfig = [
        {...GRID_CHECKBOX_SELECTION_COL_DEF, width: 60, hideable: false},
        {field: "object_id", headerName: "ID", minWidth: 50, editable: false, hideable: false, sortable: false},
        {field: "facility", headerName: "Facility", minWidth: 100, flex: 1, editable: false, sortable: false},
        {field: "name", headerName: "Name", minWidth: 160, flex: 1, editable: false, sortable: false},
        {field: "physical_address", headerName: "Physical Address", minWidth: 120, flex: 1, editable: false, sortable: false},
        {field: "time_zone", headerName: "Time Zone", minWidth: 110, flex: 1, editable: false, sortable: false},
        {field: "region", headerName: "Region", minWidth: 140, flex: 1, editable: false, sortable: false},
        {field: "group", headerName: "Group", minWidth: 120, flex: 1, editable: false, sortable: false},
        {field: "status", headerName: "Status", minWidth: 140, flex: 1, editable: false, sortable: false},
        {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, sortable: false, renderCell: (params: GridRenderCellParams) => {
                const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();

                    const id = params.row.facility;
                    navigate({pathname: `/internal_sites/${id}`})
                }

                return(
                    <Button
                        style={{
                            float: "right"}}
                        variant="text"
                        onClick={onClick}
                        disableRipple>
                        View
                    </Button>
                );
            }
        }
    ]

    useEffect(() => {
        searchCacheSet(searchCacheKey, search);
    })

    const paginationModelChanged = (newPaginationModel: {page: number, pageSize: number}): void => {
        setPaginationModel(newPaginationModel);
    };

    const filterModelChanged = (newModel: GridFilterModel): void => {
        filter.current = newModel.quickFilterValues;
        if(filter.current === undefined || filter.current?.length === 0) {
            // If search bar was cleared, return to the unfiltered data
            setSearch(filter.current);
        }
    };

    const visibilityModelChanged = (newVisibilityModel: GridColumnVisibilityModel): void => {
        setVisibilityModel(newVisibilityModel)
    }

    return (
        <Box>
            {siteInfo.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
            )}
            {!siteInfo.isError && (
                <Box>
                    <Box style={{height: 670, width: "100%"}}>
                        <CustomDataGrid
                            columnVisibilityModel={visibilityModel}
                            columnVisibilityModelChanged={visibilityModelChanged}
                            columnConfig={columnConfig}
                            itemInfo={siteInfo.data?.sites}
                            allowMultiSelect
                            paginationModel={paginationModel}
                            paginationModelChanged={paginationModelChanged}
                            rowCount={siteInfo.data?.count !== undefined ? siteInfo.data.count : 0}
                            filterModelChanged={filterModelChanged}
                            searchButtonClick={() => {setSearch(filter.current)}}
                            searchEnterKey={() => {setSearch(filter.current)}}
                            initSearch={search}
                            isLoading={siteInfo.isLoading}
                            useFilterHeader={useColumnFilters}
                            onPageChanged={(event, page) =>
                                setPaginationModel({
                                    page: page - 1,
                                    pageSize: paginationModel.pageSize
                                })
                            }
                            onPageSizeChanged={(event: SelectChangeEvent<number>) => {
                                setPaginationModel({
                                    page: paginationModel.page,
                                    pageSize: event.target.value as number
                                })
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export function InternalSites(): JSX.Element {
    return (
        <Box>
            <Banner firstLine="Internal Sites" secondLine="Search" gridBanner/>
            <Container maxWidth="xl">
                <SitesGrid searchCacheKey="sites"/>
            </Container>
        </Box>
    );
}
