import {FieldHistory, FieldHistorySet, HistoryInfo} from "./history.types";

export function ParseRevisionData(revisionData: HistoryInfo | undefined): FieldHistorySet[] {
    if(revisionData === undefined) return [];

    const revisionChanges: FieldHistorySet[] = [];

    if(revisionData.revisions !== undefined) {
        // Iterate revisions
        for(let i = 0; i < revisionData.revisions.length; i += 1) {
            // Check if revision has changes
            if(revisionData.revisions[i].changed_fields !== undefined) {
                // Iterate over revision changed
                Object.keys(revisionData.revisions[i].changed_fields).forEach((key) => {
                    // Check if this change is in results yet
                    const changedField = revisionData.revisions[i].changed_fields[key];

                    if(typeof changedField !== "object") {
                        if(revisionChanges.find(x => x.name === key) === undefined) {
                            // Create new revision item
                            const item: FieldHistorySet = {
                                name: key,
                                changes: [],
                            }

                            // Iterate over revisions and search for changes that has the same key as current revision key
                            for(let j = 0; j < revisionData.revisions.length; j += 1) {
                                let pair: [string, any] | undefined;

                                if("changed_fields" in revisionData.revisions[j]) {
                                    pair = Object.entries(revisionData.revisions[j].changed_fields).find(x => x[0] === key);
                                }
                                else if("added_fields" in revisionData.revisions[j]) {
                                    pair = Object.entries(revisionData.revisions[j].added_fields).find(x => x[0] === key);
                                }

                                if(pair !== undefined) {
                                    if(typeof pair[1] !== "object") {
                                        const ver = revisionData.revisions[j].revision;
                                        const editor = revisionData.revisions[j].updated_by;

                                        const change: FieldHistory = {
                                            value: pair[1],
                                            version: (ver === undefined ? 0 : ver) + 1,
                                            edited_by: editor === undefined ? "-" : editor,
                                            date: new Date(revisionData.revisions[j].update_time).toDateString(),
                                        }

                                        item.changes.push(change);
                                    }
                                }
                            }

                            if(item.changes.length > 0) {
                                revisionChanges.push(item);
                            }
                        }
                    }
                    else {
                        Object.keys(changedField).forEach((k) => {
                            const nestedKey = `${key}/${k}`;
                            if(revisionChanges.find(x => x.name === nestedKey) === undefined) {
                                // Create new revision item
                                const item: FieldHistorySet = {
                                    name: nestedKey,
                                    changes: [],
                                }

                                for(let n = 0; n < revisionData.revisions.length; n += 1) {
                                    let pair: [string, any] | undefined;

                                    if("changed_fields" in revisionData.revisions[n] && key in revisionData.revisions[n].changed_fields) {
                                        pair = Object.entries(revisionData.revisions[n].changed_fields[key]).find(x => x[0] === k);
                                    }
                                    else if("added_fields" in revisionData.revisions[n] && key in revisionData.revisions[n].added_fields) {
                                        pair = Object.entries(revisionData.revisions[n].added_fields[key]).find(x => x[0] === k);
                                    }

                                    if(pair !== undefined) {
                                        if(typeof pair[1] !== "object") {
                                            const ver = revisionData.revisions[n].revision;
                                            const editor = revisionData.revisions[n].updated_by;

                                            const change: FieldHistory = {
                                                value: pair[1],
                                                version: (ver === undefined ? 0 : ver) + 1,
                                                edited_by: editor === undefined ? "-" : editor,
                                                date: new Date(revisionData.revisions[n].update_time).toDateString(),
                                            }

                                            item.changes.push(change);
                                        }
                                    }
                                }

                                if(item.changes.length > 0) {
                                    revisionChanges.push(item);
                                }
                            }
                        })
                    }
                })
            }
        }
    }

    return revisionChanges;
}
