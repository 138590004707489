import axios from 'axios';

import {useQuery, UseQueryResult} from "@tanstack/react-query";
import { useAuth } from "../auth/auth.hooks";
import {EquipmentInfo, InstallationInfo, InstallationListInfo} from "./installation.types";

export const useInstallationInfo = (instId: string, limit: number | undefined, searchInThis: boolean): UseQueryResult<InstallationListInfo[]> => {
    const { accessToken, config } = useAuth();

    // The search string to the installation API has to be at least 3 characters and radio button selects this search.
    // We still have to use useQuery, because React complains if you use different amount of hooks between renders.
    // This just returns empty array.
    if(instId === "" || instId.length < 3 || !searchInThis) {
        return useQuery(['installations', { 'inst_id': instId, 'limit': limit, 'searchThis': searchInThis }], () => [], {});
    }

    return useQuery(
        ['installations', { 'inst_id': instId, 'limit': limit, 'searchThis': searchInThis }], // TODO: Add more keys
        () =>
            axios
                .get<InstallationListInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/installations/search`,
                    { params: {
                            inst_id: instId,
                            ...(limit !== undefined ? { limit } : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export  const  useInstallationDetails = (isntId: string): UseQueryResult<InstallationInfo> => {
    const { accessToken, config } = useAuth();

    return useQuery(
        ['installation', { 'inst_id': isntId }], // TODO: Add more keys
        () =>
            axios
                .get<InstallationInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/installations`,
                    { params: {
                            inst_id: isntId
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export const useEquipmentInfo = (search: string, page: number, limit: number, searchInThis: boolean): UseQueryResult<EquipmentInfo[]> => {
    const { accessToken, config } = useAuth();

    // The search string to the equipment API has to be at least 3 characters and radio button selects this search.
    // We still have to use useQuery, because React complains if you use different amount of hooks between renders.
    // This just returns empty array.
    if(search === "" || search.length < 3 || !searchInThis) {
        return useQuery(['equipment', { 'search': search, 'page': page, 'limit': limit, 'searchThis': searchInThis }], () => [], {});
    }

    return useQuery(
        ['equipment', { 'search': search, 'page': page, 'limit': limit, 'searchThis': searchInThis }], // TODO: Add more keys
        () =>
            axios
                .get<InstallationListInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/equipment`,
                    { params: {
                            search,
                            page,
                            limit,
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}

export const useEquipmentCount = (search: string): UseQueryResult<number> => {
    const { accessToken, config } = useAuth();

    return useQuery(
        ['equipment_rows', { 'search': search }],
        () =>
            axios
                .get<number>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/equipment/rows`,
                    { params: {
                            ...(search !== undefined ? { search: String(search) } : {}),
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}
