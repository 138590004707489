import React, {FormEvent, ReactNode} from "react";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    Container,
    FormControl,
    FormControlLabel,
    Input,
    InputLabel,
    MenuItem, Radio, RadioGroup,
    Select,
    SelectChangeEvent, TextField,
    Typography
} from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios, {AxiosResponse} from "axios";
import {useAuth} from "../auth/auth.hooks";
import {Banner} from "../banner/banner.component";
import {useProducts} from "./upload.hooks";
import {OtamProduct, PollerResponse} from "./upload.types";
import {IsAdmin} from "../auth/rolesAuth";

type AxiosCallback = (r: AxiosResponse) => void;

function DisplayStatusMessage(uploadStatus: string): JSX.Element {
    switch (uploadStatus) {
        case "getting-products":
            return (
                <Alert severity="info" sx={{mb: 2}}>Loading...</Alert>
            );
        case "uploading":
            return (
                <Alert severity="info" sx={{mb: 2}}>Uploading file(s)...</Alert>
            );
        case "success":
            return (
                <Alert severity="success" sx={{mb: 2}}>File(s) uploaded successfully</Alert>
            );
        case "partial":
            return (
                <Alert severity="info" sx={{mb: 2}}>Uploading some files failed</Alert>
            );
        case "fail":
            return (
                <Alert severity="error" sx={{mb: 2}}>Failed to upload file(s)</Alert>
            );
        case "wrong-type":
            return (
                <Alert severity="error" sx={{mb: 2}}>Rejected one or more files because they were incorrect type</Alert>
            );
        default:
            return (
                <Alert style={{display: "none"}} sx={{mb: 2}}>Hidden</Alert>
            );
    }
}

function UploadButton(enabled: boolean): JSX.Element {
    return(
        <Button
            style={{ color: "#ffffff", backgroundColor: enabled ? "#0f334a" : "#99b7c4" }}
            sx={{m: 0.5}}
            type="submit"
            variant="contained"
            disabled={ !enabled }>
            Upload
        </Button>
    );
}

function DropDownMenuFolder(
    value: string,
    list: string[] | undefined,
    enabled: boolean,
    collapse: boolean,
    showNewFolderField: boolean,
    newFolderValue: string,
    onChange: ((event: SelectChangeEvent<string>, child: ReactNode) => void) | undefined,
    radioChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    newFolderName: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    isAdmin: boolean,
): JSX.Element {
    let folders: JSX.Element[] = [];
    if(list !== undefined) {
        /* eslint-disable */
        folders = list.map((f) => (
            <MenuItem key={f} value={f}>
                {f}
            </MenuItem>
        ))
        /* eslint-enable */
    }

    const folderSelect = <Box>
        <FormControl>
            <InputLabel id="folder-label">Upload Folder</InputLabel>
            <Select
                sx={{ minWidth: 450 }}
                id="folder-label"
                labelId="folder-label"
                value={value}
                label="Upload Folder"
                onChange={onChange}
                disabled={ !enabled }
            >
                {folders}
            </Select>
        </FormControl>
    </Box>

    const newFolderField = <Box>
        <TextField
            id="new-folder-field"
            label="New Folder"
            variant="outlined"
            value={newFolderValue}
            onChange={newFolderName}
            sx={{minWidth: 450}}
            InputLabelProps={{ shrink: true }}
        />
    </Box>

    return(
        <Collapse in={!collapse}>
            {isAdmin && <FormControl  sx={{ mt: 1, mb: 1.5, ml: 0.5 }}>
                <RadioGroup
                    row
                    name="folder-radio"
                    defaultValue="existing"
                    onChange={radioChange}
                >
                    <FormControlLabel control={<Radio/>} value="existing" label="Existing Folder"/>
                    <FormControlLabel control={<Radio/>} value="new" label="New Folder"/>
                </RadioGroup>
            </FormControl>}
            {(isAdmin && showNewFolderField) && newFolderField}
            {!(isAdmin && showNewFolderField) && folderSelect}
        </Collapse>
    );
}

function DropDownMenuProduct(value: string, list: OtamProduct[], enabled: boolean, onChange: ((event: SelectChangeEvent<string>, child: ReactNode) => void) | undefined): JSX.Element {
    return(
        <FormControl sx={{ mt: 0.5, mb: 1.5, ml: 0.5 }}>
            <InputLabel id="product-label">Product</InputLabel>
            <Select
                sx={{ minWidth: 450 }}
                id="product-label"
                labelId="product-label"
                value={value}
                label="Product"
                onChange={onChange}
                disabled={ !enabled }
            >
                {
                    /* eslint-disable */
                    list.map((f) => (
                        <MenuItem key={f.name} value={f.name}>
                            {f.name}
                        </MenuItem>
                    ))
                    /* eslint-enable */
                }
            </Select>
        </FormControl>
    );
}

function GetColor(status: number): string {
    switch (status) {
        case 200:
            return "#a4d596"
        case 100:
            return "#ffaa7a"
        case 500:
            return "#e66969"
        default:
            return "#000000"
    }
}

function UploadStatus(fileNames: string[], pollerStatus: PollerResponse[]): JSX.Element[] {
    const items: JSX.Element[] = [];

    for(let i = 0; i < fileNames.length; i += 1) {
        let name = "";
        let message = "";
        let status = 0;

        const pItem = pollerStatus.find(x => x.name === fileNames[i])

        if(pItem !== undefined) {
            name = pItem.name;
            message = pItem.message;
            status = pItem.status;
        }
        else {
            name = fileNames[i];
            message = "Status Unknown";
            status = 100;
        }

        items.push(
            <Box style={{backgroundColor: GetColor(status), width: 450, clear: "both", overflow: "hidden"}}>
                <Box style={{float: "left"}}>
                    <Typography style={{color: "#ffffff", maxWidth: 320, paddingLeft: 9, overflow: "hidden", textOverflow: "ellipsis"}}>
                        {name}
                    </Typography>
                </Box>
                <Box style={{float: "right"}}>
                    <Typography style={{color: "#ffffff", paddingRight: 9, maxWidth: 130}}>
                        {message}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return items
}

export function Upload(): JSX.Element {
    const { accessToken, config } = useAuth();
    const [uploadIndicator, setUploadIndicator] = React.useState("hidden");
    const [uploadButton, setUploadButton] = React.useState(false);
    const [fileButton, setFileButton] = React.useState(true);
    const [uploadStatus, setUploadStatus] = React.useState("");
    const [product, setProduct] = React.useState("");
    const [folder, setFolder] = React.useState("");
    const [dropdownProduct, setDropdownProduct] = React.useState(true)
    const [dropdownFolder, setDropdownFolder] = React.useState(false)
    const [folderCollapse, setFolderCollapse] = React.useState(true)
    const [ensure, setEnsure] = React.useState(false);
    const [fileCount, setFileCount] = React.useState(0);
    const [dragover, setDragover] = React.useState(false);
    const [uploadId, setUploadId] = React.useState("");
    const [pollerStatus, setPollerStatus] = React.useState<PollerResponse[]>([]);
    const [fileNames, setFileNames] = React.useState<string[]>([]);
    const [newFolder, setNewFolder] = React.useState(false);
    const [newFolderName, setNewFolderName] = React.useState("");

    const productList = useProducts();
    const isAdmin = IsAdmin();

    const IndicatorDisplay = {
        display: "inline-block",
        height: 25,
        width: 25,
        verticalAlign: "middle"
    }

    const IndicatorHidden = {
        display: "none",
        height: 25,
        width: 25,
        verticalAlign: "middle"
    }

    const IndicatorStatus = uploadIndicator === "block" ? IndicatorDisplay : IndicatorHidden;

    React.useEffect(() => {
        window.addEventListener("dragover", (e) => {
            e.preventDefault();
        }, undefined);

        window.addEventListener("drop", (e) => {
            e.preventDefault();
        }, undefined);

        document.addEventListener("dragover", (e) => {
            e.preventDefault();

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            e.dataTransfer.dropEffect = "none";
        }, undefined);
    },[])

    React.useEffect(() => {
        if(productList.status === "success" && productList.data.length > 0) {
            setProduct(productList.data[0].name)

            if(productList.data[0].useFolder && productList.data[0].folders.length > 0) {
                setFolder(productList.data[0].folders[0])
            }

            if(productList.data[0].useFolder) {
                setDropdownFolder(true);
                setFolderCollapse(false);
            }
            else {
                setDropdownFolder(false);
                setFolderCollapse(true);
            }
        }
    },[productList.status])

    React.useEffect(() => {
        let interval: NodeJS.Timeout | undefined

        if(uploadId !== "") {
            interval = setInterval(() => {
                console.log("Polling")

                axios.get<PollerResponse[]>(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/upload/poller`,
                    {
                        params: {
                            id: uploadId
                        },
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    })
                    .then((response) => {
                        const pollerData = response.data;
                        if(pollerData !== undefined) {
                            setPollerStatus(pollerData);

                            let complete = true
                            let failCount = 0;
                            let unknownCount = 0;

                            for(let i = 0; i < pollerData.length; i += 1) {
                                if(pollerData[i].status !== 200) {
                                    if(pollerData[i].status === 500) {
                                        failCount += 1;
                                    }
                                    else {
                                        if(fileNames.findIndex(x => x === pollerData[i].name) === -1) {
                                            unknownCount += 1;
                                        }

                                        complete = false;
                                        break;
                                    }
                                }
                            }

                            if(complete) {
                                if(failCount === 0 && unknownCount === 0 ) {
                                    setUploadStatus("success");
                                }
                                else if(failCount === pollerData.length) {
                                    setUploadStatus("fail");
                                }
                                else {
                                    setUploadStatus("partial");
                                }

                                setUploadId("")
                                setUploadIndicator("hidden");
                                setFileButton(true);
                                setUploadButton(true);
                                setDropdownProduct(true);
                                setDropdownFolder(true);
                            }
                        }
                    })
            }, 3000)
        }
        else {
            // eslint-disable-next-line no-lonely-if
            if(interval !== undefined) {
                clearInterval(interval)
            }
        }

        return () => {
            if(interval !== undefined) {
                clearInterval(interval)
            }
        }
    },[uploadId])

    React.useEffect(() => {
        setFileCount(0);
        setUploadButton(false);
    }, [product])

    let types = "";
    if(productList.data !== undefined) {
        const t = productList.data.find((value) => product === value.name)?.fileExtensions;
        if(t !== undefined) {
            types = t;
        }
    }

    const inputFileChanged = (): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const inputFiles = document.getElementById("input").files;
        const t = types.split(",");

        let rejectedCount = 0;

        if (inputFiles.length > 0) {
            for(let i = 0; i < inputFiles.length; i+=1) {
                let fileExt = inputFiles[i].name.split('.').pop();
                fileExt = `.${fileExt}`;
                if(!t.includes(fileExt)) {
                    rejectedCount += 1;
                }
            }

            setFileCount(inputFiles.length - rejectedCount);

            if(rejectedCount !== inputFiles.length) {
                const pollerData: PollerResponse[] = [];
                const fileNs: string[] = [];

                for(let i = 0; i < inputFiles.length; i += 1) {
                    let fileExt = inputFiles[i].name.split('.').pop();
                    fileExt = `.${fileExt}`;
                    if(t.includes(fileExt)) {
                        pollerData.push({
                            name: inputFiles[i].name,
                            status: 100,
                            message: ""
                        })
                        fileNs.push(inputFiles[i].name);
                    }
                }

                setPollerStatus(pollerData)
                setFileNames(fileNs);
            }

            if(rejectedCount === 0) {
                setUploadStatus("file");
                setUploadIndicator("none");
                setUploadButton(true);
            }
            else {
                setUploadStatus("wrong-type");

                if(inputFiles.length === rejectedCount) {
                    setUploadButton(false);
                }
                else {
                    setUploadButton(true);
                }
            }
        } else {
            setUploadButton(false);
        }
    }

    const sendFiles = (
        form: FormData,
        type: string,
        uploadFolder: string,
        createNewFolder: boolean,
        productType: string,
        conf: Record<string, unknown>,
        callback: AxiosCallback
    ): void => {

        let productId: string | undefined;

        if(createNewFolder) {
            productId = productList.data?.find(x => x.name === product)?.id;
        }

        const postConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${accessToken}`
            },
            params: {
                target: uploadFolder,
                new_folder: createNewFolder,
                product_type: productType,
                ...(productId !== undefined ? { product_id: productId } : {})
            }
        }

        axios.post(`${conf.REACT_APP_API_BASE_URL}/${conf.REACT_APP_ENVIRONMENT}/otam/upload/asset_data`, form, postConfig)
            .then((response) => {
                callback(response);
            }).catch(error => {
            if(!error.response) {
                setUploadStatus("fail");
                setUploadIndicator("hidden");
                setFileButton(true);
                setUploadButton(true);
                setDropdownProduct(true);
                setDropdownFolder(true);
            }
            else {
                setUploadStatus("fail");
                setUploadIndicator("hidden");
                setFileButton(true);
                setUploadButton(true);
                setDropdownProduct(true);
                setDropdownFolder(true);
            }
        })
    }

    const submitForm = (e: FormEvent<HTMLFormElement>): boolean => {
        // Callback for when response from POST request is received
        const cb = (response: AxiosResponse): void => {
            if (response.status === 200) {
                setUploadId(response.data.id);
            } else {
                setUploadId("");
                setUploadStatus("fail");
                setUploadIndicator("hidden");
                setFileButton(true);
                setUploadButton(true);
                setDropdownProduct(true);
                setDropdownFolder(true);
            }
        }

        e.preventDefault();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const inputFiles = document.getElementById("input").files;

        // Check if a file is selected
        if (inputFiles.length > 0) {
            setUploadStatus("uploading");
            setUploadIndicator("block");
            setFileButton(false);
            setUploadButton(false);
            setDropdownProduct(false);
            setDropdownFolder(false);

            const productType = productList.data?.find((value) => value.name === product)?.type;

            if(productType === undefined) {
                setUploadIndicator("hidden")
                return false;
            }

            const t = types.split(",");
            const formData = new FormData();
            const pollerData: PollerResponse[] = [];

            for(let i = 0; i < inputFiles.length; i += 1) {
                let fileExt = inputFiles[i].name.split('.').pop();
                fileExt = `.${fileExt}`;
                if(t.includes(fileExt)) {
                    formData.append("files", inputFiles[i]);
                    pollerData.push({
                        name: inputFiles[i].name,
                        status: 100,
                        message: "Uploading"
                    })
                }
            }

            setPollerStatus(pollerData)

            const folderName = (isAdmin && newFolder) ? newFolderName : folder;

            // Create the POST request and send the files
            sendFiles(formData, "", folderName, isAdmin ? newFolder : false, productType, config, cb);

            return true;
        }

        // No file selected
        setUploadIndicator("hidden")
        return false;
    }

    const dropzoneMessage = uploadStatus === "uploading" ?
        <Typography>Uploading...</Typography> :
        <Typography>Drop files here ({types}), or click to select files<br/>{fileCount} files selected</Typography>

    return(
        <Box>
            <Banner firstLine="Asset Data" secondLine="Upload"/>
            <Container maxWidth="xl">
                {productList.isLoading && (
                    <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>
                )}
                {productList.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
                )}
                {(productList.isSuccess && productList.data.length === 0) && (
                    <Alert severity="info" sx={{ mb: 2 }}>No upload destinations available. Please request the appropriate role from the OTAM team.</Alert>
                )}
                {(productList.isSuccess && productList.data.length > 0) && (
                    <Box>
                        <div>
                            {DisplayStatusMessage(uploadStatus)}
                            <form onSubmit={submitForm}>
                                <Box sx={{ maxWidth: "900px"}}>
                                    <label htmlFor="selected-product">
                                        {DropDownMenuProduct(product, productList.data, dropdownProduct, (event) => {
                                            setProduct(event.target.value)
                                            const p = productList.data.find((value) => event.target.value === value.name)

                                            if(p !== undefined) {
                                                if(p.useFolder) {
                                                    setDropdownFolder(true);
                                                    setFolderCollapse(false);
                                                    setFolder(p.folders[0])
                                                }
                                                else {
                                                    setDropdownFolder(false);
                                                    setFolderCollapse(true);
                                                }
                                            }
                                        })}
                                    </label>
                                    <label htmlFor="selected-folder">
                                        {DropDownMenuFolder(
                                            folder,
                                            productList.data.find(p => p.name === product)?.folders,
                                            dropdownFolder,
                                            folderCollapse,
                                            newFolder,
                                            newFolderName,
                                            (event) => {
                                                setFolder(event.target.value)
                                            },
                                            (event) => {
                                                setNewFolder(event.target.value === "new");
                                            },
                                            (event) => {
                                                setNewFolderName(event.target.value);
                                            },
                                            isAdmin)}
                                    </label>
                                </Box>
                                {/* label tag max width is set to smaller than the width of the child element so the
                                 click area doesn't extend outside the visible area. Normally the label is 100% width,
                                 thus extending outside the child element. Height is fine as is. */}
                                <label htmlFor="input" style={{display: "block", maxWidth: "1px"}}>
                                    <Box style={{
                                        borderStyle: "solid",
                                        borderColor: "#c0c0c0",
                                        backgroundColor: dragover ? "#e8e8f8" : "#e8e8e8",
                                        borderWidth: "2px",
                                        height: "150px",
                                        width: "446px",
                                        marginLeft: "4px",
                                        marginTop: "8px",
                                        marginBottom: "8px",
                                        textAlign: "center",
                                        display: "table",
                                        transition: "all .5s ease",
                                        WebkitTransition: "all .5s ease",
                                        MozTransition: "all .5s ease",
                                        cursor: fileButton ? "pointer" : "default",
                                    }}
                                    onDrop={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        if(uploadStatus === "uploading") {
                                            return;
                                        }

                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        document.getElementById("input").files = event.dataTransfer.files;
                                        inputFileChanged();

                                        if(dragover) {
                                            setDragover(false);
                                        }
                                    }}
                                    onDragOver={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        if(uploadStatus === "uploading") {
                                            return;
                                        }

                                        // eslint-disable-next-line no-param-reassign
                                        event.dataTransfer.dropEffect = "copy";

                                        if(!dragover) {
                                            setDragover(true);
                                        }
                                    }}
                                    onDragEnter={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        if(uploadStatus === "uploading") {
                                            return;
                                        }

                                        setDragover(true);
                                    }}
                                    onDragLeave={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();

                                        if(uploadStatus === "uploading") {
                                            return;
                                        }

                                        setDragover(false);
                                    }}>
                                        <Box style={{
                                            display: "table-cell",
                                            verticalAlign: "middle",
                                        }}>
                                            <Input
                                                onChange={inputFileChanged}
                                                style={{display: "none"}}
                                                inputProps={{
                                                    accept: types,
                                                    multiple: true
                                                }}
                                                type="file"
                                                id="input"
                                                name="filename"
                                                disabled={ !fileButton }
                                            />
                                            <Box>
                                                <FileUploadIcon style={{color: "#a0a0a0", fontSize: 32}}/>
                                                {dropzoneMessage}
                                            </Box>
                                        </Box>
                                    </Box>
                                </label>
                                <label>
                                    <FormControlLabel sx={{ml: "-6px"}} control={<Checkbox size="medium" defaultChecked={false} value={ensure} onChange={() => {
                                        setEnsure(!ensure);
                                    }}/>} label="I ensure that all data has been filled correctly"/>
                                </label>
                                <br/>
                                <label htmlFor="upload-file">
                                    {UploadButton(uploadButton && ensure)}
                                </label>
                                <CircularProgress
                                    sx={{m: 1}}
                                    style={IndicatorStatus}
                                />
                                <Box sx={{ mt: "12px"}}>
                                    {UploadStatus(fileNames, pollerStatus)}
                                </Box>
                            </form>
                        </div>
                    </Box>
                )}
            </Container>
        </Box>
    );
}
