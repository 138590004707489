import React, { FormEvent } from "react";
import axios from "axios";
import {useQueryClient, QueryClient} from "@tanstack/react-query";
import {Alert, Box, Container, Divider, Typography} from "@mui/material";
import {useAuth} from "../../auth/auth.hooks";
import {PatchValidation} from "../validation.types";
import {CancelButton, EditButton, InfoDropdownPair, InfoFieldPair, UploadStatusMessage} from "../../viewPageCommonElements/viewPage.component";
import {GetString} from "../../../localization";
import {Banner} from "../../banner/banner.component";
import {useValidationItem} from "../validation.hooks";



export const withHooksHOC = (Component: React.ElementType) => function withHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();
    return <Component accessToken={accessToken} config={config} queryClient={queryClient}/>;
}

interface ShowSesProps {
    objectId: string;
}

interface InfoBoxProps {
    name: string;
    data: PatchValidation;
    content: string[];
}

interface ViewValidationProps {
    editing: boolean;
    updating: boolean;
    updateStatus: string;
    dataLoaded: boolean;
}

interface IHooksHOCProps {
    accessToken: string;
    queryClient: QueryClient;
    config: Record<string, unknown>;
}

const patchInfoContents: string[] = [
    'instance_name',
    'short_description',
    'related_item',
    'discovery_year',
    'discovery_month',
    'patch_status',
    'vendor_article_link',
    'vendor_patch_link',
    'patch_hash',
]

const RelatedItems = [
    "Ubuntu 20.04",
    "Ubuntu 22.04",
    "Windows Server 2012 R2",
    "Windows Server 2016 SP0",
    "Windows Server 2022 SP0",
]

const PatchStatus = [
    "Approved",
    "Rejected",
    "Superseded",
]

class ViewPatch extends React.Component<any, ViewValidationProps> {
    static instId = "";

    static objectId = "";

    static FormatFieldData(data: number | string | undefined): string {
        if (undefined) {
            return "";
        }
        return data as string;
    }

    static GetYearList(): string[] {
        const year = (new Date()).getFullYear();
        const years = Array.from(new Array(10),( val, index) => String(year - index));
        return years;
    }

    static GetMonthList(): string[] {
        const months = Array.from(new Array(12),( val, index) => String(1 + index));
        return months;
    }

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            editing: false,
            updating: false,
            updateStatus: "none",
            dataLoaded: false,
        }
        this.InfoBox = this.InfoBox.bind(this);
        this.ShowPatch = this.ShowPatch.bind(this);
        this.EditFormSubmit = this.EditFormSubmit.bind(this);
        this.PostData = this.PostData.bind(this);
    }

    PostData = (data: string, postType: string): void => {
        const {queryClient} = this.props;
        const {config} = this.props;
        const {accessToken} = this.props;

        const conf = {
            params: {
                id: ViewPatch.objectId,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        }

        axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/validation/${postType}`, data, conf)
            .then((response) => {
                if(response.status === 200) {
                    // Backend data has been updated, so local cache is no longer valid
                    queryClient.invalidateQueries(['validation', { 'id': ViewPatch.objectId }], { exact: true })
                }

                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "success"
                })
            }).catch(error => {
            if(!error.response) {
                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "failed"
                })
            }
            else {
                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "failed"
                })
            }
        })
    }

    EditFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        const {editing} = this.state;

        e.preventDefault();

        if(!editing) {
            this.setState({
                editing: true
            });
        }

        if(editing) {
            this.setState({
                updating: true,
                updateStatus: "updating"
            })

            const form = document.getElementById("editForm") as HTMLFormElement;
            const data = new FormData(form);

            if (ViewPatch.objectId !== "add") data.append("patch_id", ViewPatch.objectId);
            else data.append("patch_id", "0");
            const object = {} as PatchValidation;
            data.forEach((value, key)=> {
                object[key] = value as string;
            });

            const json = JSON.stringify(object);
            if (ViewPatch.objectId === "add") {
                this.PostData(json, "add")
            }
            else {
                this.PostData(json, "update")
            }
        }
    }

    InfoBox = ({name, data, content}: InfoBoxProps): JSX.Element => {
        const {FormatFieldData} = ViewPatch;
        const {GetYearList} = ViewPatch;
        const {GetMonthList} = ViewPatch;
        const {updating} = this.state;
        const {editing} = this.state;
        let editMode = editing;
        if (ViewPatch.objectId === "add") {
            editMode = true;
        }
        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px"}}>
                <Typography style={{color: "#000000", fontWeight: "bold", fontSize: "larger"}}>
                    {name}
                </Typography>
                <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                <Box style={{display: "flex"}}>
                    <Box style={{float: "left", width: "30%"}}>
                        <InfoFieldPair title={GetString(content[0])} name={content[0]} data={FormatFieldData(data[content[0]])} updating={updating} editing={editMode}/>
                        <InfoFieldPair title={GetString(content[1])} name={content[1]} data={FormatFieldData(data[content[1]])} updating={updating} editing={editMode}/>
                        <InfoDropdownPair title={GetString(content[2])} name={content[2]} data={FormatFieldData(data[content[2]])} dropdownOptions={RelatedItems} updating={updating} editing={editMode}/>

                    </Box>
                    <Box style={{float: "left", width: "30%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoDropdownPair title={GetString(content[3])} name={content[3]} data={FormatFieldData(data[content[3]])} dropdownOptions={GetYearList()} updating={updating} editing={editMode}/>
                        <InfoDropdownPair title={GetString(content[4])} name={content[4]} data={FormatFieldData(data[content[4]])} dropdownOptions={GetMonthList()} updating={updating} editing={editMode}/>
                        <InfoDropdownPair title={GetString(content[5])} name={content[5]} data={FormatFieldData(data[content[5]])} dropdownOptions={PatchStatus} updating={updating} editing={editMode}/>
                    </Box>
                    <Box style={{float: "right", width: "30%", flexGrow: 2}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[6])} name={content[6]} data={FormatFieldData(data[content[6]])} updating={updating} editing={editMode}/>
                        <InfoFieldPair title={GetString(content[7])} name={content[7]} data={FormatFieldData(data[content[7]])} updating={updating} editing={editMode}/>
                        <InfoFieldPair title={GetString(content[8])} name={content[8]} data={FormatFieldData(data[content[8]])} updating={updating} editing={editMode}/>
                    </Box>
                </Box>
            </Box>
        );
    }

    ShowPatch = ({objectId}: ShowSesProps): JSX.Element => {
        if(objectId === "") {
            return <Alert severity="error" sx={{ mb: 2 }}>ID was not provided</Alert>
        }

        const {InfoBox} = this;
        const {dataLoaded} = this.state;

        if(objectId === "add") {
            const newPatch: PatchValidation = {
                instance_name: "",
                short_description: "",
                related_item: "",
                patch_status: "",
                vendor_article_link: "",
                vendor_patch_link: "",
                patch_hash: "",
                object_id: -1,
            };

            return (
                <Box>
                    <Box className="viewAssetContainer">
                        <InfoBox
                            name="Asset Information"
                            data={newPatch} content={patchInfoContents}
                        />
                    </Box>
                </Box>
            );
        }
        const assetData = useValidationItem(objectId);

        if(assetData.isSuccess && !dataLoaded) {
            this.setState({
                dataLoaded: true
            });
        }

        return (
            <Box>
                {assetData.isLoading && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}
                {assetData.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Asset data could not be loaded</Alert>
                )}
                {assetData.isSuccess && (
                    <Box>
                        <Box className="viewAssetContainer">
                            <InfoBox
                                name="Asset Information"
                                data={assetData.data} content={patchInfoContents}
                            />
                            {/* <AssetInfoBox
                            name="Installation Details"
                            data={assetData.data[0]} content={assetInfoContents}
                        /> */}
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }

    render(): JSX.Element {
        const {ShowPatch} = this;
        const {editing} = this.state;
        const {updating} = this.state;
        const {updateStatus} = this.state;
        const {dataLoaded} = this.state;

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const params = parts[2].split("+")

        // eslint-disable-next-line prefer-destructuring
        ViewPatch.instId = decodeURIComponent(params[1]);
        // eslint-disable-next-line prefer-destructuring
        ViewPatch.objectId = params[0];
        let editMode = editing;
        if (ViewPatch.objectId === "add") {
            editMode = true;
        }
        const bannerText = ViewPatch.objectId === "add" ? "Add new patch" : `View Asset ID ${ViewPatch.instId}`;
        return (
            <Box>
                <Banner firstLine="Patch Data" secondLine={bannerText}/>
                <Container maxWidth="xl">
                    <form id="editForm" onSubmit={this.EditFormSubmit}>
                        <Box style={{ float: "right" }}>
                            {ViewPatch.objectId !== "add" && (
                                <CancelButton
                                    editing={editing}
                                    updating={updating}
                                    onClick={() => {
                                        this.setState({
                                            editing: false
                                        })
                                    }}
                                />
                            )}
                            <EditButton requiredRoles={["otam_admin", "otam_patch_validation"]} editing={editMode} updating={updating} enabled={dataLoaded}/>
                        </Box>
                        <Box className="clearBoth">
                            <UploadStatusMessage status={updateStatus}/>
                        </Box>
                        <Box>
                            <ShowPatch objectId={ViewPatch.objectId}/>
                        </Box>
                    </form>
                </Container>
            </Box>
        );
    }
}

export default withHooksHOC(ViewPatch);
