/*
 * This script is a slightly modified version of https://github.com/neo/react-use-scroll-position/blob/master/index.ts
 */

import { useState, useEffect } from 'react';

interface ScrollPosition {
    x: number;
    y: number;
}

function getScrollPosition(): ScrollPosition {
    return { x: window.scrollX, y: window.scrollY };
}

export function useScrollPosition(): ScrollPosition {
    const [position, setScrollPosition] = useState<ScrollPosition>(getScrollPosition());

    useEffect(() => {
        let requestRunning: number | null = null;
        function handleScroll(): void {
            if (requestRunning === null) {
                requestRunning = window.requestAnimationFrame(() => {
                    setScrollPosition(getScrollPosition());
                    requestRunning = null;
                });
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return position;
}

export function useScrollXPosition(): number {
    const { x } = useScrollPosition();
    return x;
}

export function useScrollYPosition(): number {
    const { y } = useScrollPosition();
    return y;
}

export function useClickOutside(ref: any, openElem: HTMLElement | null, onClickOutside: () => void): void {
    useEffect(() => {
        function handleClick(event: MouseEvent): void {
            if(ref.current && !ref.current.contains(event.target)) {
                const target = event.target as HTMLElement;

                if(target !== openElem && target.parentElement !== openElem) {
                    onClickOutside();
                }
            }
        }

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [ref])
}









































