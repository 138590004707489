// This is a slightly modified version of MaterialUI's GridToolbarQuickFilter
// Original: https://github.com/mui/mui-x/blob/master/packages/grid/x-data-grid/src/components/toolbar/GridToolbarQuickFilter.tsx
//
// The difference is the use of 'searchValueGlobal', which keeps the search field value in the search
// bar when the page re-renders. The code that uses this component must be a function, otherwise
// the DataGrid won't accept it. This causes this component to be overwritten by a new component on
// re-render, causing useStates to lose their values when the page re-renders. This wouldn't be an
// issue if a const of this component could have been used instead.

import * as React from 'react';
import PropTypes from 'prop-types';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { debounce } from '@mui/material/utils';
import {useGridApiContext, useGridRootProps} from "@mui/x-data-grid";

const GridToolbarQuickFilterRoot = styled(TextField, {
    name: 'MuiDataGrid',
    slot: 'ToolbarQuickFilter',
    overridesResolver: (props, styles) => styles.toolbarQuickFilter,
})(({ theme }) => ({
    width: 'auto',
    paddingBottom: theme.spacing(0.5),
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const defaultSearchValueParser = (searchText: string): string[] =>
    searchText.split(' ').filter((word) => word !== '');

export type GridToolbarQuickFilterProps = TextFieldProps & {
    initialSearchVal: string | undefined;
    quickFilterParser?: (input: string) => string[];
    debounceMs?: number;
};

function CustomGridToolbarQuickFilter(props: GridToolbarQuickFilterProps): JSX.Element {
    const { quickFilterParser = defaultSearchValueParser, debounceMs = 0, initialSearchVal, ...other } = props;

    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();

    const [searchValue, setSearchValue] = React.useState(initialSearchVal);

    const updateSearchValue = React.useCallback(
        (newSearchValue: string) => {
            apiRef.current.setQuickFilterValues(quickFilterParser(newSearchValue));
        },
        [apiRef, quickFilterParser],
    );

    const debouncedUpdateSearchValue = React.useMemo(
        () => debounce(updateSearchValue, debounceMs),
        [updateSearchValue, debounceMs],
    );

    const handleSearchValueChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newSearchValue = event.target.value;
            setSearchValue(newSearchValue);
            debouncedUpdateSearchValue(newSearchValue);
        },
        [debouncedUpdateSearchValue],
    );

    return (
        <GridToolbarQuickFilterRoot
            sx={{
                '.MuiInput-underline:before': {
                    borderBottom: "1px solid #cccccc"
                },
                '.MuiInput-underline:after': {
                    borderBottom: "1px solid #ffffff"
                },
                '.css-v4u5dn-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                    borderBottom: "1px solid #cccccc"
                },
                div: {
                    color: "#ffffff",
                },
            }}
            as={rootProps.slots.baseTextField}
            variant="standard"
            value={searchValue}
            onChange={handleSearchValueChange}
            placeholder={apiRef.current.getLocaleText('toolbarQuickFilterPlaceholder')}
            aria-label={apiRef.current.getLocaleText('toolbarQuickFilterLabel')}
            type="search"
            InputProps={{
                startAdornment: <rootProps.slots.quickFilterIcon fontSize="small" />,
            }}
            {...other}
            {...rootProps.slotProps?.baseTextField}
        />
    );
}

CustomGridToolbarQuickFilter.propTypes = {
    debounceMs: PropTypes.number,
    quickFilterParser: PropTypes.func,
} as any;

export { CustomGridToolbarQuickFilter };
