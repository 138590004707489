import {Box} from "@mui/material";
import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {AuthenticateRolesLocally, RoleAuthResult} from "../auth/rolesAuth";
import {useScrollYPosition} from "../misc.hooks";

interface DropdownMenuProps {
    name: string;
    items: DropDownItem[];
    posX: number;
    posY: number;
    path: string;
}

interface DropdownListProps {
    items: DropDownItem[];
    posX: number;
    posY: number;
}

interface DropdownLinkProps {
    text: string;
    path: string;
}

export type DropDownItem = {
    text: string;
    path: string;
    requiredRoles: string[];
    [key: string]: string | string[];
}

function DropdownLink({text, path}: DropdownLinkProps): JSX.Element {
    return(
        <Box className="navigationButton">
            {/* This is not a MUI component, so it can't be styled with the global theme in index.tsx */}
            <NavLink to={path}
                     style={{
                         color: "#ffffff",
                         paddingTop: 12,
                         paddingLeft: 22,
                         paddingRight: 22,
                         paddingBottom: 12,
                         fontFamily: "Noto Sans",
                         fontSize: 16,
                         width: "auto",
                         display: "block",
                         fontWeight: "lighter",
                         textDecoration: "none",
                     }}
                     onKeyDown={(event) => {
                         // Prevent event propagation to the parent, which would cause the dropdown to
                         // close and not selecting the link and navigating to next page
                         if(event.key === "Enter") {
                            event.stopPropagation();
                         }
                     }}>
                {text}
            </NavLink>
        </Box>
    );
}

function DropdownMenuList({items, posX, posY}: DropdownListProps): JSX.Element | null {
    const content = [];

    if(items !== undefined && items.length > 0) {
        for(let i = 0; i < items.length; i += 1) {
            const {requiredRoles} = items[i];
            if(requiredRoles.length > 0 && AuthenticateRolesLocally(requiredRoles) === RoleAuthResult.RoleFound) {
                content.push(
                    <li
                        key={i}
                        style={{
                            backgroundColor: "#0f172b",
                            whiteSpace: "nowrap",
                        }}>
                        <DropdownLink text={items[i].text} path={items[i].path}/>
                    </li>);
            }
        }
    }

    if(content.length > 0) {
        return(
            <Box sx={{
                position: "absolute",
                width: "auto",
                paddingTop: "4px",
                top: posY,
                left: posX,
            }}>
                <ul style={{
                    width: "auto",
                    listStyleType: "none",
                    padding: 0,
                    margin: 0,
                }}>
                    {content}
                </ul>
            </Box>
        );
    }

    return null;
}

function dropdownLinkSelected(path: string, items: DropDownItem[]): boolean {
    for(let i = 0; i < items.length; i += 1) {
        if(items[i].path === path) {
            return true;
        }
    }

    return false;
}

function getAllDropdownRoles(items: DropDownItem[]): string[] {
    const roles: string[] = []

    for(let i = 0; i < items.length; i += 1) {
        for(let j = 0; j < items[i].requiredRoles.length; j += 1) {
            const currentRole = items[i].requiredRoles[j];

            if(roles.indexOf(currentRole) === -1) {
                roles.push(currentRole);
            }
        }
    }

    return roles;
}

export function DropdownMenuComponent({name, items, posX, posY, path}: DropdownMenuProps): JSX.Element | null {
    const dropdownLink = dropdownLinkSelected(path, items);
    const [visible, setVisible] = useState(false);
    const scrollY = useScrollYPosition();

    // Check that user has at least one role required to see something in the dropdown
    // If not, hide the dropdown button
    if(AuthenticateRolesLocally(getAllDropdownRoles(items)) !== RoleAuthResult.RoleFound) {
        return null;
    }

    if(scrollY > 52 && visible) {
        setVisible(false);
    }

    return(
        <Box
            tabIndex={0}
            className="navigationButton"
            style={{
                position: "relative",
                color: "#ff7321",
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
                paddingTop: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingBottom: 12,
                fontFamily: "Noto Sans",
                fontSize: 16,
                fontWeight: dropdownLink ? "normal" : "lighter",
                textTransform: "none",
                textDecoration: dropdownLink ? "underline" : "none",
                textUnderlineOffset: 14,
                textDecorationThickness: dropdownLink ? 5 : 0,
                cursor: "pointer",
            }}
            onMouseEnter={() => {
                setVisible(true);
            }}
            onMouseLeave={() => {
                setVisible(false);
            }}
            onTouchEnd={() => {
                if(!visible) {
                    setVisible(!visible);
                }
            }}
            onKeyDown={(event) => {
                if(event.key === "Enter") {
                    setVisible(!visible);
                }
            }}
            onBlur={(event) => {
                // Close dropdown menu if focus moves to element that is not this element's child
                if(!event.currentTarget.contains(event.relatedTarget)) {
                    setVisible(false);
                }
            }}
        >
            <Box style={{ color: "#ffffff" }}>
                <Box>
                    {name}
                    <KeyboardArrowDownIcon sx={{ position: "relative", top: 7 }}/>
                </Box>

            </Box>
            <Box style={{ clear: "both" }}>
                {visible && (<DropdownMenuList items={items} posX={posX} posY={posY}/>)}
            </Box>
        </Box>
    );
}
